exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-validate-tsx": () => import("./../../../src/pages/validate.tsx" /* webpackChunkName: "component---src-pages-validate-tsx" */),
  "component---src-pages-vote-tsx": () => import("./../../../src/pages/vote.tsx" /* webpackChunkName: "component---src-pages-vote-tsx" */),
  "component---src-scenes-bird-tsx": () => import("./../../../src/scenes/bird.tsx" /* webpackChunkName: "component---src-scenes-bird-tsx" */),
  "component---src-scenes-page-tsx": () => import("./../../../src/scenes/page.tsx" /* webpackChunkName: "component---src-scenes-page-tsx" */)
}

